import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import bankList from "@/config/bank.json"
import citiesList from "@/config/citiesList.json"

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {

    }
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'cooperation-provider',
      defaultData: this._defaultData,
      dataAction: {
        status: false,
        back: false,
        delete: false,
        refresh: false,
        save: false,
      },
      blocks: [
        {
          data: {
            // 'pivot.status': {
            //   label: 'cooperation.status',
            //   type: 'readonly-content',
            //   readonly: true,
            //   itemClass: (formInfo) => {
            //     const status = formInfo.formData.pivot.status
            //     // @ts-ignore
            //     if (status == vm.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT) return 'red--text'
            //     return 'primary--text'
            //   },
            //   text: (data, formInfo) => {
            //     return vm.$t(`provider.status.detail.${data}`)
            //   },
            // },
            member_name: {
              label: 'provider.data.member_name',
              type: 'liff-text',
              readonly: true,
            },
            phone: {
              label: 'provider.data.phone',
              type: 'liff-text',
              readonly: true,
            },
            email: {
              label: 'data.email',
              type: 'liff-text',
              readonly: true,
            },
            'pivot.payment_type': {
              label: 'data.payment_type',
              type: 'liff-selection',
              readonlyPrefix: 'data.payment_type',
              readonly: true,
              horizon: true,
              hideDetails: true,
              hideLabel: true,
              options: (formInfo) => {
                const paymentTypes = formInfo.formData.payment_type
                return paymentTypes.map((type: string) => ({ text: vm.$t(`data.payment_type.${type}`), value: type }))
              },
            },
            'pivot.comment': {
              label: 'provider.data.comment',
              type: 'liff-text',
              readonly: true,
            },
            'pivot.reason': {
              label: "拒絕原因",
              type: 'readonly-content',
              readonly: true,
              create: (form) => {
                //@ts-ignore
                return form.formData.pivot.status == vm.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
              },
            },
          },
        },

      ],
    }
  }

}

export default new formConfig()
